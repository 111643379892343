import http from '../../api/http'
import Config from '../../../config'
import { showNotification } from "../notification";
import { closeTransactionDialog } from '../redux_actions';

export function SetWalletAccountLoading(loadingMsg) {
    return (dispatch) => {
        dispatch({ type: "WALLET_ACCOUNT_LOADING", loadingMsg: loadingMsg });
    };
}

export function GetWalletAccount(accountId, paymentId) { 

    let apiBaseUrl =
    process.env.REACT_APP_IDENTITY_BASE_URL +
    process.env.REACT_APP_USER_ACCOUNTS_URI.concat(accountId);

    let params = {
        params: {
            include: Config.REACT_APP_CLIENTS_INCLUDE,
        },
    };

    const request = http.get(apiBaseUrl, { params });

    return (dispatch) => {

        dispatch(SetWalletAccountLoading('Loading user wallet information...'));

        request.then(
            ({ data }) => {
                dispatch({ type: "FETCH_A_WALLET_ACCOUNT", payload: data, accountId: accountId, paymentId: paymentId });
            },
            (error) => {
                dispatch({ type: "ERROR_WALLET_ACCOUNT", errorMsg: error.messages });
            }
        );
    };

}

export function GetReceiverWalletAccount(accountId) { 

    let apiBaseUrl =
    process.env.REACT_APP_IDENTITY_BASE_URL +
    process.env.REACT_APP_USER_ACCOUNTS_URI.concat(accountId);

    let params = {
        params: {
            include: Config.REACT_APP_CLIENTS_INCLUDE,
        },
    };

    const request = http.get(apiBaseUrl, { params });

    return (dispatch) => {

        dispatch(SetWalletAccountLoading('Loading other wallet information...'));

        request.then(
            ({ data }) => {
                dispatch({ type: "FETCH_A_RECEIVER_WALLET_ACCOUNT", payload: data, accountId: accountId });
            },
            (error) => {
                dispatch({ type: "ERROR_WALLET_ACCOUNT", errorMsg: error.messages });
            }
        );
    };

}

export function DoWalletTransaction(objParam, actionMethod){

    let apiBaseUrl = process.env.REACT_APP_IDENTITY_BASE_URL + process.env.REACT_APP_WALLET_URI.concat(`${actionMethod.toLowerCase()}`);

    let params = { ...objParam };
    const request = http.post(apiBaseUrl, params);

    return (dispatch) => {

        dispatch(SetWalletAccountLoading("Request transaction in progress..."));

        request.then(({
            data
        }) => {

            if(["TRANSFER", "PAY"].includes(actionMethod.toUpperCase())){
                dispatch({ type: 'OTP_SENT', payload: data })
            }else{
                dispatch({ type: 'TRANSACTION_SENT_SUCCESSFULLY', payload: data })
            }

            dispatch(showNotification({ type: "success", message: "Request has successfully been executed!" }));
        },
            (error) => {
                dispatch(showNotification({ type: "error", message: error.messages }));
                dispatch({ type: 'ERROR_WALLET_ACCOUNT', errorMsg: error.messages })
            });
    }

}

export function DoBankTransaction(objParam){

    let apiBaseUrl = process.env.REACT_APP_IDENTITY_BASE_URL + process.env.REACT_APP_MICROFINANCE_URI.concat('/pay-loan');

    let params = { ...objParam };
    const request = http.post(apiBaseUrl, params);

    return (dispatch) => {

        dispatch(SetWalletAccountLoading("Request transaction in progress..."));

        request.then(({
            data
        }) => {

            dispatch({ type: 'OTP_SENT', payload: data })
            dispatch(showNotification({ type: "success", message: "Bank payment has successfully been executed!" }));

        },
            (error) => {
                dispatch(showNotification({ type: "error", message: error.messages }));
                dispatch({ type: 'ERROR_WALLET_ACCOUNT', errorMsg: error.messages })
            });
    }

}


export function Deposit() {

}

// send money or transfer money
export function Transfer() {

}

export function Withdraw() {

}

export function Topup() {

}

export function GetWalletTransaction(transactionId) {

}

export function GetWalletTransactionList() {


}

export function confirmOTP(otp, actionMethod) {

    let apiBaseUrl =
    process.env.REACT_APP_IDENTITY_BASE_URL + process.env.REACT_APP_WALLET_OTP.concat(`${otp}`);
  
    const request = http.patch(apiBaseUrl);
  
    return (dispatch) => {
  
      dispatch(SetWalletAccountLoading("OTP confirmation in progress..."));
  
      request.then(({
        data
      }) => {

        if(["REQUEST"].includes(actionMethod.toUpperCase())){
            
            dispatch({ type: 'REQUEST_ACCEPTED_OR_REJECT' });
            dispatch(closeTransactionDialog());

        }else{
            dispatch({ type: 'TRANSACTION_SENT_SUCCESSFULLY', payload: data })
        }
        dispatch(showNotification({ type: "success", message: "Request has successfully been executed!" }));

      },
        (error) => {
            dispatch(showNotification({ type: "error", message: error.messages }));
            dispatch({ type: 'OTP_HAS_ERROR', otpErrorMsg: error.messages })
        });
    }
  
  }

  export function resendOTP(sessionId) {

    let apiBaseUrl =
    process.env.REACT_APP_IDENTITY_BASE_URL + process.env.REACT_APP_WALLET_OTP;
  
    let params = { sessionId };
    const request = http.post(apiBaseUrl, params);
  
    return (dispatch) => {
  
      dispatch(SetWalletAccountLoading("OTP re-generation in progress..."));
  
      request.then(({
        data
      }) => {
        dispatch({ type: 'OTP_RESENT', payload: data })
        dispatch(showNotification({ type: "success", message: "One time password has resent sucessfully!" }));
      },
        (error) => {
            dispatch(showNotification({ type: "error", message: error.messages }));
            dispatch({ type: 'OTP_HAS_ERROR', otpErrorMsg: error.messages })
        });
    }
  
  }

  export function otpHasErrored(msg = 'Invalid OTP'){
    return (dispatch) => {
        dispatch({ type: "OTP_HAS_ERROR", otpErrorMsg: msg });
    }
  }