import React, { Component } from 'react';
import { ToastContainer, toast } from 'react-toastify';
import { connect } from 'react-redux';
import 'react-toastify/dist/ReactToastify.css';

import { Media } from 'reactstrap';

const contentSuccess = (title, message) => (
    <Media>
        <Media middle left className="mr-3">
            <i className="fa fa-fw fa-2x fa-check"></i>
        </Media>
        <Media body>
            <Media heading tag="h6">
                {title || "Request Success!"}
            </Media>
            <p>
                {message}
            </p>
        </Media>
    </Media>
);
// eslint-disable-next-line react/prop-types
const contentError = (title, message) => {
    console.log('contentError',title, message)
    return (
        <Media>
            <Media middle left className="mr-3">
                <i className="fa fa-fw fa-2x fa-close"></i>
            </Media>
            <Media body>
                <Media heading tag="h6">
                    {title || "An Error Occured!"}
                </Media>
                <p>
                    {message}
                </p>
            </Media>
        </Media>
    )
};
// eslint-disable-next-line react/prop-types
const contentInfo = (title, message) => (
    <Media>
        <Media middle left className="mr-3">
            <i className="fa fa-fw fa-2x fa-info"></i>
        </Media>
        <Media body>
            <Media heading tag="h6">
                {title || "Information!"}
            </Media>
            <p>
                {message}
            </p>
        </Media>
    </Media>
);
// eslint-disable-next-line react/prop-types
const contentWarning = (title, message) => (
    <Media>
        <Media middle left className="mr-3">
            <i className="fa fa-fw fa-2x fa-exclamation"></i>
        </Media>
        <Media body>
            <Media heading tag="h6">
                {title || "Warning!"}
            </Media>
            <p>
                {message}
            </p>
        </Media>
    </Media>
);

class Notification extends Component {
    generateNotification(notification) {
        let options = {
            onClose: () => {
                this.props.dissmissNotif()
            }
        }

        console.log('this.generateNotification', notification)

        switch (notification.type.toUpperCase()) {
            case "SUCCESS":
                return toast.success(contentSuccess(notification.title, notification.message), options);
            case "INFO":
                return toast.info(contentInfo(notification.title, notification.message), options);
            case "ERROR":
                return toast.error(contentError(notification.title, notification.message), options);
            case "WARNING":
                return toast.warning(contentWarning(notification.title, notification.message), options);
            default:
                return null
        }
    }
    componentDidUpdate(newProps) {
        Object.keys(newProps.notification).map((notificationId) => {
            return this.generateNotification(newProps.notification[notificationId]);
        });
    }
    render() {
        return (
            <ToastContainer position={toast.POSITION.TOP_RIGHT}
                autoClose={5000}
                hideProgressBar={true}
                newestOnTop
                closeOnClick
                rtl={false}
                pauseOnVisibilityChange
                draggable
                pauseOnHover
            />
        )
    }
}

const mapStateToProps = (state) => {
    return {
        notification: state.notification
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        dissmissNotif: () => {
            //general dimisser for loading states
            dispatch({
                type: 'NOTIFICATION_DISSMISS'
            })

        }
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Notification);