import { combineReducers } from "redux";

import theme_reducer from './theme_reducer'
import notification from './notification';
import auth from './authentication/auth';
import users from './ewallet/users';
import accounts from './ewallet/accounts';
import loyalty from './ewallet/loyalty';
import requestmoney from './ewallet/requestmoney';
import ewallet from './ewallet/wallet';
import transactions from './ewallet/transactions';
import dashboard from './ewallet/dashboard';

import redux_actions from './redux_actions';

import send_money from "./remittance/send_money";
import beneficiary_list from "./remittance/beneficiary_list";
import currency from "./remittance/currencies";
import fees from "./remittance/fees";
import forex from "./remittance/forex";
import payouts from "./remittance/payouts";
import customer_transactions from "./remittance/customer_transactions";
import remit_transactions from "./remittance/transactions";
import clientTransactions from "./remittance/transactions";
import purposes from "./settings/purposes";

export default combineReducers({
    theme_reducer,
    notification,
    auth,
    users,
    accounts,
    loyalty,
    ewallet,
    transactions,
    dashboard,
    requestmoney,
    redux_actions,
    send_money,
    beneficiary_list,
    purposes,
    currency,
    fees,
    payouts,
    forex,
    customer_transactions,
    remit_transactions,
    clientTransactions
})