
import _ from "lodash";


const formatPlaceholder = (objFieldValue) => {
    let { name } = objFieldValue
    let formName = (_.includes(name,'.')) ? name.split('.').pop() : name 
    let labelText = _.startCase(formName)
    return labelText
}

//build string by using object {name:"my name", required:true}
const formatLabel = (objFieldValue) => {
    let { name, required } = objFieldValue
    
    let formName = (_.includes(name,'.')) ? name.split('.').pop() : name 

    let labelText = _.startCase(formName)
    labelText = formatRequired(labelText, required)

    return labelText
}


const formatRequired = (strLabel, required) => {
    let labelText = strLabel + (required ? ' *' : '')
    return labelText
}

const formHelper = {
    formatPlaceholder,
    formatLabel,
    formatRequired
}

export default formHelper