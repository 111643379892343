import Config from "../../../config";

const initState = {
  open: false,
  error: false,
  errorMsg: "",
  firstLoad: true,
  loaded: false,
  loading: false,
  loading_image: false,
  loadingText: "Processing Request...",
  meta: Config.defaultMeta,
  data: [],
  selectedForex: {
    baseCurrency: {
      id: undefined
    },
    id: undefined,
    quoteCurrency: {
      id: undefined
    },
    rate: ""
  },
  forexHistory: {
    data: [],
    meta: Config.defaultMeta,
    firstLoad: true,
    loaded: false,
    loading: false
  },
  forexCreated: false,
  forexAction: null,
  forexId: null,
  defaultSorted: [
    {
      id: "createdAt",
      desc: true
    }
  ],
  dropdownOpen: new Array(10).fill(false)
};

export default function forex(state = initState, action) {
  switch (action.type) {
    case "FETCH_FOREX":
      return {
        ...state,
        error: false,
        data: action.data,
        meta: action.meta,
        loading: false,
        loading_image: false,
        loaded: true,
        firstLoad: false
      };

    case "FETCH_FOREX_HISTORY":
      return {
        ...state,
        forexHistory: {
          ...state.forexHistory,
          error: false,
          data: action.data,
          meta: action.meta,
          loading: false,
          loading_image: false,
          loaded: true,
          firstLoad: false
        }
      };

    case "FETCH_A_FOREX":
      return {
        ...state,
        error: false,
        selectedForex: {
          ...action.payload
        },
        forexId: action.forexId,
        loading: false,
        loading_image: false
      };

    case "FOREX_CREATED":
      return {
        ...state,
        error: false,
        selectedForex: {
          ...action.payload
        },
        forexCreated: true,
        loading: false,
        loading_image: false
      };

    case "FOREX_UPDATED":
      return {
        ...state,
        error: false,
        updatedreated: true,
        loading: false,
        loading_image: false
      };

    case "ERROR_FOREX":
      return {
        ...state,
        error: true,
        errorMsg: action.errorMsg.messages,
        loading: false,
        loading_image: false
      };

    case "FOREX_LOADING":
      return {
        ...state,
        error: false,
        loading: true,
        loadingText: action.loadingMsg,
        loading_image: false
      };

    case "FOREX_LIST_DEFAULT":
    case "DISMISS_ERROR":
    case "CLEAR_FOREX_DATA":
      return initState;

    //for general notification
    case "NOTIFICATION_DISSMISS":
      return {
        ...state,
        loading: false,
        loading_image: false
      };

    default:
      return state;
  }
}
