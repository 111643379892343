/**
 * src/api/http.js
 */
 import axios from 'axios';
 import { v4 as uuidv1 } from 'uuid';
 
 import qs from 'qs';
 import _ from 'lodash';
 
 import { getRemittanceAuthHeader } from '../actions/authentication/auth';
 import Config from '../../config';
 
 /**
  * axios instance
  */
 let instance = axios.create({
   paramsSerializer: function (params) {
     return qs.stringify(params, { encode: false })
   }
 })
 
 /**
  *
  * parse error response
  */
 function parseError(messages, errorCode = false) {
   // error
 
   console.log('parseError', messages, errorCode)
 
   let strError = '';
 
   if (messages) {
     if (!_.isEmpty(messages.info)) {
       messages.info.forEach((error) => {
         strError += error.message + '. ';
       })
       return Promise.reject({ messages: strError })
     }
     else if (messages instanceof Array) {
       console.log('messages', messages)
       messages.forEach((error) => {
         if (error.messages) {
           error.title = error.messages[0].toString();
         }
         strError += error.title + '. ';
       })
       return Promise.reject({ messages: strError.toString() })
     } else {
       //general error message
       if (messages != null){
         if (errorCode === 401) {
           console.log('Error Code', errorCode)
           let errorMSG = 'Session has expired.  You will be logged-off.'
           localStorage.clear()
           localStorage.setItem('error', 'Session has expired.  Log-in again.')
           window.location.reload()
           return Promise.reject({ messages: errorMSG, reload: true })
         }else{
           return Promise.reject({ messages: messages.message })
         }
       }
       return Promise.reject({ messages: 'There was a problem in connecting to the server. Please check your internet and try again.' })
     }
   } else {
 
     if (errorCode) {
       //reload 401
       //TODO: use redux to reload
       
       if (errorCode === 401) {
         console.log('Error Code', errorCode)
         let errorMSG = 'Session has expired.  You will be logged-off.'
         localStorage.clear()
         localStorage.setItem('error', 'Session has expired.  Log-in again.')
         window.location.reload()
         return Promise.reject({ messages: errorMSG, reload: true })
       }
     }
 
     //general error message
     return Promise.reject({ messages: 'There was a problem in connecting to the server. Please check your internet and try again.' })
   }
 
 }
 
 /**
 * parse response
 */
 function parseBody(response) {
   console.log('parseBody', response);
   if (response.status === 200) {
     return response
   } else if (response.status === 201) {
     return response
   } else if (response.status === 400) {
     return this.parseError(response.data)
   }
   else if (response.status === 405) {
       return this.parseError(response.data)
   } else if (response.status === 202) {
     return response
   } else {
     return this.parseError(response.data)
   }
 }
 
 
 // request header
 instance.interceptors.request.use((config) => {
   // Simply add the token on the headers when getting data
 
   let finalHeaders = {}
 
   let apiToken = getRemittanceAuthHeader(true);
   if (apiToken) {
     finalHeaders['Authorization'] = getRemittanceAuthHeader(true)
   }
 
   if (config.url.includes(Config.REACT_APP_WALLET_CALCULATION_URI)) {
     finalHeaders['Content-Type'] = 'application/json'
     finalHeaders['x-idempotency-key'] = uuidv1()
   }
 
   //all other security headers for all 
   finalHeaders['X-Frame-Options'] = 'sameorigin'
   finalHeaders['X-XSS-Protection'] = '1; mode=block'
   finalHeaders['Strict-Transport-Security'] = 'max-age=63072000; includeSubDomains; preload'
   finalHeaders['X-Content-Type-Options'] = 'nosniff'
 
   config.headers = finalHeaders
 
 
   return config
 }, error => {
   return Promise.reject(error)
 })
 
 // response parse
 instance.interceptors.response.use((response) => {
   return parseBody(response)
 }, error => {
   // console.warn('Error status', error.response.status)
   // return Promise.reject(error)
 
   //TODO: add refresh token when token is expired
 
   if (error && error.response) {
 
     let responseError = null;
     let statusCode = error.response.status;
 
     if(error.response.data.errors instanceof Array){
       responseError = error.response.data.errors;
     }else{
       responseError = error.response.data;
       statusCode = error.response.data.code;
     }
     return parseError(responseError, statusCode)
   } else {
     return Promise.reject(error)
   }
 })
 
 const http_remit = instance
 export default http_remit
 