
import http from "../../api/http";
import http_remit from "../../api/http_remit";
import Config from "../../../config";
import { showNotification } from "../notification";
import { setReduxDropdownActionLength } from '../redux_actions';
import _ from 'lodash';

const strGeneralError =
    "There was a problem in connecting to the server. Please check your internet and try again.";
    
export function ClearSelectedAccount() {
    return (dispatch) => {
        dispatch({ type: "CLEAR_SELECTED_ACCOUNTS" });
    };
}

export function dismissAccountError() {
    return (dispatch) => {
        dispatch({ type: "DISMISS_ACCOUNT_ERROR" });
    };
}

export function SetAccountLoading(loadingMsg) {
    return (dispatch) => {
        dispatch({ type: "ACCOUNT_LOADING", loadingMsg: loadingMsg });
    };
}

export function GetAccount(accountId) {

    let apiBaseUrl =
        process.env.REACT_APP_IDENTITY_BASE_URL +
        process.env.REACT_APP_USER_ACCOUNTS_URI.concat(accountId);

    let params = {
        params: {
            include: Config.REACT_APP_CLIENTS_INCLUDE,
        },
    };

    const request = http.get(apiBaseUrl, { params });

    return (dispatch) => {
        request.then(
            ({ data }) => {
                dispatch({ type: "FETCH_A_ACCOUNT", payload: data, accountId: accountId });
            },
            (error) => {
                dispatch({ type: "ERROR_ACCOUNTS", errorMsg: error.messages });
            }
        );
    };
}

export function GetAccountList(state, instance, customerOnly) {

    let apiBaseUrl =
        process.env.REACT_APP_IDENTITY_BASE_URL + process.env.REACT_APP_USER_ACCOUNTS_URI;

    let params = {};

    if(customerOnly){
        params = { role: 'customer'}
    }

    const request = http.get(apiBaseUrl, {
        params,
    });

    return (dispatch) => {
        request.then(
            ({ data }) => {
                dispatch({ type: "FETCH_ACCOUNTS", meta: data.meta, payload: data });
                dispatch(setReduxDropdownActionLength(data && data.length))
            },
            (error) => {
                if (
                    String(error) ===
                    "TypeError: Cannot read property 'parseError' of undefined"
                ) {
                    dispatch({ type: "ACCOUNT_LOADING_STOP" });
                } else {
                    dispatch(showNotification({ type: "error", message: error.messages }));
                    dispatch({ type: "ERROR_ACCOUNTS", errorMsg: strGeneralError });
                }
            }
        );
    };
}

export function CreateAccount(objParams) {
    let apiBaseUrl =
        process.env.REACT_APP_IDENTITY_BASE_URL + process.env.REACT_APP_USER_ACCOUNTS_URI;

    let params = {
        ...objParams,
    }

    const request = http.post(apiBaseUrl, params);

    return (dispatch) => {

        dispatch(SetAccountLoading("Creating account information..."));

        request.then(({
            data
        }) => {

            dispatch({ type: 'ACCOUNT_CREATED', payload: data })
            dispatch(showNotification({ type: "success", message: "Account has successfully been created!" }));

        },
            (error) => {
                dispatch(showNotification({ type: "error", message: error.messages }));
                dispatch({ type: 'ERROR_ACCOUNTS', errorMsg: error.messages, payload: params });
            });
    }
}

export function UpdateAccount(objParams, accountId, changePassword) {

    let apiBaseUrl =
        process.env.REACT_APP_IDENTITY_BASE_URL + process.env.REACT_APP_USER_ACCOUNTS_URI.concat(`${accountId}`);

    let params = { ...objParams };
    const request = http.put(apiBaseUrl, params);

    return (dispatch) => {

        dispatch(SetAccountLoading("Updating account information..."));

        request.then(({
            data
        }) => {

            dispatch({ type: 'ACCOUNT_UPDATED', payload: data })
            dispatch(showNotification({ type: "success", message: "Account has successfully been updated!" }));

            // logout user
            if(changePassword){
                dispatch({ type: 'LOGOUT' })
            }

        },
        (error) => {
            dispatch(showNotification({ type: "error", message: error.messages }));
            dispatch({ type: 'ERROR_ACCOUNTS', errorMsg: error.messages })
        });
    }
}

export function ReplaceAccounts(objParams) { }

// KYC
export function UploadRequiredDocuments(objData, kycInformation, customerId, remittanceCustomerId, kycStarted) {

    let apiBaseUrl = process.env.REACT_APP_IDENTITY_BASE_URL + process.env.REACT_APP_MULTI_UPLOAD_URI;

    var formData = new FormData();

    let files = { ...objData };

    if(Array.isArray(objData)){

      for (var key in objData) {
        formData.append("files", objData[key]);
      }

      //build form header
      http.interceptors.request.use(config => {
        config.headers = { "Content-Type": "multipart/form-data" };
        return config;
      });
  
      const request = http.post(apiBaseUrl, formData);
  
      return dispatch => {
        request.then(
          ({ data }) => {
            
            let documents = [];
            _.map(data, (f) => {

                let type = _.find(files, (file) => { return file.name === f.originalname}) || "";

                documents.push({
                    "filename": f.filename,
                    "documentType": type.fileType
                })
            })
         
            if(!_.isEmpty(documents))
                kycInformation.documents = documents;
            
            if(kycInformation.status === "draft" && !kycStarted){
                dispatch(StartKYC(kycInformation, customerId));
            }else{
                dispatch(UpdateKYC(kycInformation, customerId, remittanceCustomerId));
            }

          },
          error => {
            console.log("Error Request", error);
            dispatch({ type: "ERROR_SAVINGS", errorMsg: error.messages });
            dispatch(showNotification({ type: "error", message: error.messages }));
          }
        );
      };
    }
}

export function StartKYC(kycInformation, customerId){
    
    let apiBaseUrl = `${process.env.REACT_APP_REMITTANCE_BASE_URL}${"api/v1/customer"}`;

    if(kycInformation){
        var data =  {
            "type": "kycInformation",
            "attributes": {
                "firstName": kycInformation.firstname || "",
                "middleName": kycInformation.middlename || "",
                "lastName": kycInformation.lastname || "",
                "email": kycInformation.email || "",
                "gender": kycInformation.gender || "",
                "phone": kycInformation.phoneNumber || "",
                "dateOfBirth": kycInformation.dateOfBirth || "",
                "countryCode": "AE",
                "countryFirstLevel": null,
                "streetAddr": null,
                "postcode": "00000",
                "city": kycInformation.city || "dubai",
                "accountNumber": "1234567890",
                "bankIban": "AE180030023412342351234",
                "bankName": "Abu Dhabi Commercial Bank",
                "bankCode": "ADCB",
                "bankBic": null,
                "documents": [],
                "status": kycInformation.status || "draft",
            }
        }

        console.log(kycInformation)

        const request = http_remit.post(apiBaseUrl, {data})

        return (dispatch) => {

            request.then(({
                data
            }) => {
               
                dispatch(startWalletKyc(kycInformation, customerId, data.data.id))

            },
            (error) => {
            
            });
        }
    }
}

export function startWalletKyc(kycInformation, customerId, remittanceCustomerId){

    let apiBaseUrl =
        process.env.REACT_APP_IDENTITY_BASE_URL + 
        process.env.REACT_APP_USER_URI.concat(`${customerId}/kyc`);

    let params = {
        ...kycInformation,
        remittanceCustomerId: remittanceCustomerId
    }

    const wallet_request = http.post(apiBaseUrl, params);

    return (dispatch) => {

        dispatch(SetAccountLoading("Creating kyc information..."));

        wallet_request.then(({
            data
        }) => {

            dispatch({ type: 'KCY_CREATED', payload: data })
            dispatch(showNotification({ type: "success", message: "KYC information has successfully been created!" }));

        },
        (error) => {
            console.log("MAKER", error);

            dispatch({ type: "ERROR_ACCOUNTS", errorMsg: error.messages, payload: params });
            dispatch(showNotification({ type: "error", message: error.messages }));
        
        });
    }
    
}

export function StartUpdateKYC(kycInformation, customerId, remittanceCustomerId){


    let apiBaseUrl =
        process.env.REACT_APP_IDENTITY_BASE_URL + 
        process.env.REACT_APP_USER_URI.concat(`${customerId}/kyc`);

    let params = {
        ...kycInformation,
        remittanceCustomerId
    }
    const request = http.put(apiBaseUrl, params);

    return (dispatch) => {

        dispatch(SetAccountLoading("Updating kyc status information..."));

        request.then(({
            data
        }) => {

            console.log('KCY_UPDATED', data);

            dispatch({ type: 'KCY_UPDATED', payload: params })
            dispatch(showNotification({ type: "success", message: "KYC information has successfully been updated!" }));
        },
        (error) => {

            console.log(error);

            dispatch({ type: "ERROR_ACCOUNTS", errorMsg: error.messages, payload: params });
            dispatch(showNotification({ type: "error", message: error.messages }));
            
        });
    }


}

export function UpdateKYC(kycInformation, customerId, remittanceCustomerId){

    let apiBaseUrl = `${process.env.REACT_APP_REMITTANCE_BASE_URL}${"api/v1/customer/"}${remittanceCustomerId}`;

    try{
      
        if(!kycInformation.documents && kycInformation.documents.length <=0)
            throw new Error("Invalid documents");

        if(kycInformation){
            var data =  {
                "type": "kycInformation",
                "attributes": {
                    "firstName": kycInformation.firstname || "",
                    "middleName": kycInformation.middlename || "",
                    "lastName": kycInformation.lastname || "",
                    "email": kycInformation.email || "",
                    "gender": kycInformation.gender || "",
                    "phone": kycInformation.phoneNumber || "",
                    "dateOfBirth": kycInformation.dateOfBirth || "",
                    "countryCode": "AE",
                    "countryFirstLevel": null,
                    "streetAddr": null,
                    "postcode": "00000",
                    "city": kycInformation.city || "dubai",
                    "accountNumber": "1234567890",
                    "bankIban": "AE180030023412342351234",
                    "bankName": "Abu Dhabi Commercial Bank",
                    "bankCode": "ADCB",
                    "bankBic": null,
                    "documents": [],
                    "status": kycInformation.status.toLowerCase() || "draft",
                }
            }
    
            const request = http_remit.put(apiBaseUrl, {data})
    
            return (dispatch) => {
    
                request.then(({
                    data
                }) => {
                   
                    dispatch(StartUpdateKYC(kycInformation, customerId, remittanceCustomerId))
    
                },
                (error) => {
                
                });
            }
        }
    }
    catch (error) {

    }
    
}

// cards
export function GetCardList(state, instance, customerId) {

    let apiBaseUrl =
        process.env.REACT_APP_IDENTITY_BASE_URL + process.env.REACT_APP_ACCOUNT_CARDS;

    let params = {};
    const request = http.get(apiBaseUrl, {
        params,
    });

    return (dispatch) => {
        request.then(
            ({ data }) => {
                dispatch({ type: "FETCH_CARDS", meta: data.meta, payload: data });
                dispatch(setReduxDropdownActionLength(data && data.length))
            },
            (error) => {
                if (
                    String(error) ===
                    "TypeError: Cannot read property 'parseError' of undefined"
                ) {
                    dispatch({ type: "ACCOUNT_LOADING_STOP" });
                } else {
                    dispatch(showNotification({ type: "error", message: error.messages }));
                    dispatch({ type: "ERROR_ACCOUNTS", errorMsg: strGeneralError });
                }
            }
        );
    };
}

export function CreateCards(objParams) {
    let apiBaseUrl =
        process.env.REACT_APP_IDENTITY_BASE_URL + process.env.REACT_APP_ACCOUNT_CARDS;

    let params = {
        ...objParams,
    }

    const request = http.post(apiBaseUrl, params);

    return (dispatch) => {

        dispatch(SetAccountLoading("Creating cards information..."));

        request.then(({
            data
        }) => {

            dispatch({ type: 'ACCOUNT_CARD_CREATED' })
            dispatch(showNotification({ type: "success", message: "Account cards has successfully been created!" }));

        },
            (error) => {
                dispatch(showNotification({ type: "error", message: error.messages }));
                dispatch({ type: 'ERROR_ACCOUNTS', errorMsg: error.messages, payload: params });
            });
    }
}


export function GetAccountRewards(accountId) {

    let apiBaseUrl =
        process.env.REACT_APP_IDENTITY_BASE_URL +
        process.env.REACT_APP_USER_ACCOUNTS_URI.concat(accountId);

    let params = {
        params: {
            include: Config.REACT_APP_CLIENTS_INCLUDE,
        },
    };

    const request = http.get(apiBaseUrl, { params });

    return (dispatch) => {
        request.then(
            ({ data }) => {
                dispatch({ type: "FETCH_ACCOUNT_REWARDS", payload: data, accountId: accountId });
            },
            (error) => {
                dispatch({ type: "ERROR_ACCOUNTS", errorMsg: error.messages });
            }
        );
    };
}


export function GetAccountVouchers(accountId) {

    let apiBaseUrl =
        process.env.REACT_APP_IDENTITY_BASE_URL +
        process.env.REACT_APP_USER_ACCOUNTS_URI.concat(accountId);

    let params = {
        params: {
            include: Config.REACT_APP_CLIENTS_INCLUDE,
        },
    };

    const request = http.get(apiBaseUrl, { params });

    return (dispatch) => {
        request.then(
            ({ data }) => {
                dispatch({ type: "FETCH_ACCOUNT_VOUCHERS", payload: data, accountId: accountId });
            },
            (error) => {
                dispatch({ type: "ERROR_ACCOUNTS", errorMsg: error.messages });
            }
        );
    };
}

// Banking app

export function LinkAccount(objParams, userId) {
    let apiBaseUrl =
        process.env.REACT_APP_IDENTITY_BASE_URL + process.env.REACT_APP_MICROFINANCE_URI.concat('/link');

    let params = {
        ...objParams,
    }

    const request = http.post(apiBaseUrl, params);

    return (dispatch) => {

        dispatch(SetAccountLoading("Linking account to microfinance information..."));

        request.then(({
            data
        }) => {

            dispatch({ type: 'ACCOUNT_LINKED' })
            dispatch(showNotification({ type: "success", message: "Account has successfully been created!" }));

            dispatch(SetAccountLoading("Updating user information"));
            dispatch(GetAccount(userId));

        },
            (error) => {
                dispatch(showNotification({ type: "error", message: error.messages }));
                dispatch({ type: 'ERROR_LINKING', errorMsg: error.messages });
            });
    }
}

export function UnlinkAccount(objParams, userId) {
    let apiBaseUrl =
        process.env.REACT_APP_IDENTITY_BASE_URL + process.env.REACT_APP_MICROFINANCE_URI.concat('/unlink');

    let params = {
        ...objParams,
    }

    const request = http.patch(apiBaseUrl, params);

    return (dispatch) => {

        dispatch(SetAccountLoading("Unlinking account to microfinance information..."));

        request.then(({
            data
        }) => {

            dispatch({ type: 'ACCOUNT_UNLINKED' })
            dispatch(showNotification({ type: "success", message: "Account has successfully been created!" }));

            dispatch(SetAccountLoading("Updating user information"));
            dispatch(GetAccount(userId));

        },
            (error) => {
                dispatch(showNotification({ type: "error", message: error.messages }));
                dispatch({ type: 'ERROR_LINKING', errorMsg: error.message });
            });
    }
}


export function GetBankAccount() {

    let apiBaseUrl =
        process.env.REACT_APP_IDENTITY_BASE_URL +
        process.env.REACT_APP_MICROFINANCE_URI.concat('/accounts');

    const request = http.get(apiBaseUrl);

    return (dispatch) => {

        dispatch(SetAccountLoading("Fetching bank account information..."));

        request.then(
            ({ data }) => {
                dispatch({ type: "FETCH_BANK_ACCOUNT", payload: data });
            },
            (error) => {
                dispatch({ type: "ERROR_ACCOUNTS", errorMsg: error.messages });
            }
        );
    };
}

