import Jsona from "jsona";
import http_remit from "../../api/http_remit";
import Config from "../../../config";
import { reactTableFilters, reactTableSort } from "../utils";
import { showNotification } from "../notification";

import { setReduxDropdownActionLength } from "../redux_actions";

const strGeneralError =
  "There was a problem in connecting to the server. Please check your internet and try again.";

const {
  pageSize: defaultPageSize,
  pageIndex: defaultPageIndex
} = Config.defaultMeta;

export function fetchPurpose(state) {
  let apiBaseUrl =
    process.env.REACT_APP_REMITTANCE_BASE_URL + process.env.REACT_APP_PURPOSE_URI;

  let params = {
    ...Config.defaultMeta
  };

  //override defaults if there is a state
  if (state) {
    let { pageIndex, pageSize, sortBy, filters } = state;

    params = {
      page: {
        size: pageSize,
        number: pageIndex
      }
    };

    if (sortBy)
      if (sortBy.length > 0) {
        params = {
          ...params,
          sort: reactTableSort(sortBy)
        };
      }

    if (filters)
      if (filters.length > 0) {
        params = {
          ...params,
          filter: reactTableFilters(filters)
        };
      }
  } else {
    params = {
      page: {
        number: defaultPageIndex + 1,
        size: defaultPageSize
      }
    };
  }

  params.module = "purpose";
  params.useSwitchAuthentication = true;

  const request = http_remit.get(apiBaseUrl, { params });

  const dataFormatter = new Jsona();

  return dispatch => {
    request.then(
      ({ data }) => {
        let res = dataFormatter.deserialize(data);

        dispatch(setReduxDropdownActionLength(data.meta.itemTotal));
        dispatch({
          type: "FETCH_PURPOSE",
          data: res,
          meta: data.meta
        });
      },
      error => {
        console.log("Error Request", error);
      }
    );
  };
}

export function fetchAllPurposes() {
  let apiBaseUrl =
    process.env.REACT_APP_REMITTANCE_BASE_URL + process.env.REACT_APP_PURPOSE_URI;

  var params = {
    useSwitchAuthentication: true
  };
  const request = http_remit.get(apiBaseUrl, { params });

  const dataFormatter = new Jsona();

  return dispatch => {
    request.then(
      ({ data }) => {
        let res = dataFormatter.deserialize(data);

        dispatch({
          type: "FETCH_PURPOSE",
          data: res,
          meta: data.meta
        });
      },
      error => {
        console.log("Error Request", error);
      }
    );
  };
}

export function fetchAPurpose(purposeId) {
  let apiBaseUrl =
    process.env.REACT_APP_REMITTANCE_BASE_URL + process.env.REACT_APP_PURPOSE_URI;
  apiBaseUrl += "/" + purposeId;

  let params = {
    params: {
      include: Config.REACT_APP_CLIENTS_INCLUDE
    },
    useSwitchAuthentication: true
  };

  const request = http_remit.get(apiBaseUrl, { params });

  const dataFormatter = new Jsona();

  return dispatch => {
    request.then(
      ({ data }) => {
        let res = dataFormatter.deserialize(data);

        dispatch({
          type: "FETCH_A_PURPOSE",
          payload: res,
          purposeId: purposeId
        });
      },
      error => {
        dispatch(showNotification({ type: "error", message: error }));
        dispatch({ type: "ERROR_PURPOSE", errorMsg: strGeneralError });
      }
    );
  };
}

export function createPurpose(objFormValues) {
  let apiBaseUrl =
    process.env.REACT_APP_REMITTANCE_BASE_URL + process.env.REACT_APP_PURPOSE_URI;

  let objParams = {
    data: {
      type: "Client",
      attributes: {
        ...objFormValues
      }
    }
  };

  var params = {
    useSwitchAuthentication: true
  };

  const request = http_remit.post(apiBaseUrl, objParams, { params });

  const dataFormatter = new Jsona();

  return dispatch => {
    dispatch(setPurposeLoading("Creating client address..."));

    request.then(
      ({ data }) => {
        let res = dataFormatter.deserialize(data);

        dispatch({ type: "PURPOSE_CREATED", payload: res });
        dispatch(
          showNotification({
            type: "success",
            message: "Purpose has successfully been created!"
          })
        );
      },
      error => {
        dispatch({
          type: "ERROR_PURPOSE",
          errorMsg: error
        });
      }
    );
  };
}

export function updatePurpose(objFormValues, purposeId) {
  let apiBaseUrl =
    process.env.REACT_APP_REMITTANCE_BASE_URL + process.env.REACT_APP_PURPOSE_URI;
  apiBaseUrl += "/" + purposeId;

  var params = {
    useSwitchAuthentication: true
  };

  const request = http_remit.put(
    apiBaseUrl,
    {
      data: {
        type: "Client",
        attributes: {
          ...objFormValues
        }
      }
    },
    { params }
  );

  return dispatch => {
    request.then(
      ({ data }) => {
        dispatch(setPurposeLoading("Creating client address..."));
        dispatch({ type: "PURPOSE_UPDATED" });
        dispatch(
          showNotification({
            type: "success",
            message: "Purpose has successfully been updated!"
          })
        );
      },
      error => {
        dispatch({
          type: "ERROR_PURPOSE",
          errorMsg: error
        });
      }
    );
  };
}

export function setPurposeLoading(loadingMsg) {
  return dispatch => {
    dispatch({
      type: "PURPOSE_LOADING",
      loadingMsg: loadingMsg
    });
  };
}

export function defaultPurpose() {
  return dispatch => {
    dispatch({
      type: "PURPOSE_LIST_DEFAULT"
    });
  };
}

export function dismissError() {
  return dispatch => {
    dispatch({ type: "DISMISS_ERROR" });
  };
}
