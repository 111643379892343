import { showNotification } from "./notification";


export function copiedToClipboard(copied) {
  return dispatch => {
    dispatch(showNotification({ type: "info", message: copied, title:  "COPIED TO CLIPBOARD" }));
  };
}

export function setReduxDropdownActionLength(menuLength) {
    return dispatch => {
      dispatch({
        type: "SET_DROPDOWN_ACTION_LENGTH",
        menuLength: menuLength
      });
    };
  }
  
  export function toggleReduxActions(toggle) {
    return dispatch => {
      dispatch({
        type: "UPDATE_REDUX_ACTIONS_MENUS",
        toggle: toggle
      });
    };
  }
  
  export function setUserAction(user_action) {
    return dispatch => {
      dispatch({
        type: "USER_ACTION",
        user_action: user_action
      });
    };
  }

  export function showTransactionDialog(customerId) {
    return dispatch => {
      dispatch({
        type: "SHOW_TRANSACTION_DIALOG",
        transactionId: customerId
      });
    };
  }

  export function closeTransactionDialog() {
    return dispatch => {
      dispatch({
        type: "HIDE_TRANSACTION_DIALOG"
      });
    };
  }
  
  export function showTransactionLoading() {
    return dispatch => {
      dispatch({
        type: "SHOW_TRANSACTION_DIALOG_LOADER"
      });
    };
  }
