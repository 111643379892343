import Config from "../../../config";

const initState = {
  open: false,
  error: false,
  errorMsg: "",
  firstLoad: true,
  loaded: false,
  loading: false,
  loading_image: false,
  loadingText: "Processing Request...",
  meta: Config.defaultMeta,
  data: [],
  selectedCurrency: {
    currencyCode: "",
    currencyLocation: "",
    currencyName: "",
    address: "",
    createdAt: "",
    description: "",
    type: "",
    updatedAt: ""
  },
  currencyCreated: false,
  currencyAction: null,
  currencyId: null,
  defaultSorted: [
    {
      id: "createdAt",
      desc: true
    }
  ],
  dropdownOpen: new Array(10).fill(false)
};

export default function currency(state = initState, action) {
  switch (action.type) {
    case "FETCH_CURRENCY":
      return {
        ...state,
        error: false,
        data: action.data,
        meta: action.meta,
        loading: false,
        loading_image: false,
        loaded: true,
        firstLoad: false
      };

    case "FETCH_A_CURRENCY":
      return {
        ...state,
        error: false,
        selectedCurrency: {
          ...action.payload
        },
        currencyId: action.currencyId,
        loading: false,
        loading_image: false
      };

    case "CURRENCY_CREATED":
      return {
        ...state,
        error: false,
        selectedCurrency: {
          ...action.payload
        },
        currencyCreated: true,
        loading: false,
        loading_image: false
      };

    case "CURRENCY_UPDATED":
      return {
        ...state,
        error: false,
        updatedreated: true,
        loading: false,
        loading_image: false
      };

    case "ERROR_CURRENCY":
      return {
        ...state,
        error: true,
        errorMsg: action.errorMsg.messages,
        loading: false,
        loading_image: false
      };

    case "CURRENCY_LOADING":
      return {
        ...state,
        error: false,
        loading: true,
        loadingText: action.loadingMsg,
        loading_image: false
      };

    case "CURRENCY_LIST_DEFAULT":
    case "DISMISS_ERROR":
    case "CLEAR_CURRENCY_DATA":
      return initState;

    //for general notification
    case "NOTIFICATION_DISSMISS":
      return {
        ...state,
        loading: false,
        loading_image: false
      };

    default:
      return state;
  }
}
