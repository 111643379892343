import React from "react";
import { connect } from "react-redux";

import {
  CountryDropdown,
  RegionDropdown
} from "react-country-region-selector";

class CountryAndRegionSelector extends React.Component {
  constructor(props) {
    super(props);

    this.state = { country: null, region: null };
    this.selectCountry = this.selectCountry.bind(this);
    this.selectRegion = this.selectRegion.bind(this);

  }

  selectCountry(value) {
    if (this.props.selectCountry !== undefined) {
      this.setState({ country: value });
      this.props.selectCountry(value);
    }
  }

  selectRegion(value) {
    if (this.props.selectRegion !== undefined) {
      this.setState({ region: value });
      this.props.selectRegion(value);
    }
  }

  render() {

    const { country, region } = this.state;

    return (
        <div className="row">
            <div className="col-md-6">
                <div className="input-item input-with-label">
                    <label className="input-item-label required">Country</label>
                    <div className="select-wrapper">
                        <CountryDropdown
                            value={country !== null ? country : this.props.countryValue}
                            name="country"
                            required
                            className="input-bordered"
                            onChange={this.selectCountry} 
                        />
                    </div>
                </div>
            </div>
            <div className="col-md-6">
                <div className="input-item input-with-label">
                    <label className="input-item-label required">Region/City</label>
                        <RegionDropdown
                            country={country !== null ? country : this.props.countryValue}
                            value={region !== null ? region : this.props.regionValue}
                            name="city"
                            className="input-bordered"
                            required
                            onChange={this.selectRegion} 
                        />
                </div>
            </div>
        </div>
    ); 

}

}

const mapStateToProps = state => {
  return {
      ...state.accounts
  };
};

const mapDispatchToProps = dispatch => {
  return {};
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CountryAndRegionSelector);
