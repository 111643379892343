import React from "react";
import { connect } from "react-redux";
import { fetchAllPayout } from "../../../store/actions/remittance/payouts";

import { AvField } from "availity-reactstrap-validation";
import _ from "lodash";

class PayoutSelector extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};

    this.handleChange = this.handleChange.bind(this);
  }

  componentDidMount() {
    this.props.fetchPayouts();
  }

  handleChange(event) {
    if (this.props.onChange !== undefined) {
      var selectedPayout = _.find(this.props.list.channels, [
        "name",
        event.target.value
      ]);
      this.props.onChange(event, selectedPayout);
    }
  }

  render() {
    if (this.props.returnCode) {
      return _.map(this.props.list.data, (objValue, index) => {
        if (parseInt(objValue.id) === this.props.value) {
          return (
            <span key={index} className='text-bold'>
              {objValue.code}
            </span>
          );
        }
      });
    } else {
      return (
        <AvField
          type='select'
          name={this.props.name}
          id={this.props.id}
          label={this.props.label}
          required={this.props.required}
          placeholder={this.props.placeholder}
          style={{ width: "100%" }}
          value={this.props.value}
          disabled={this.props.disabled}
          usecodes={this.props.usecodes}
          onChange={this.handleChange}
        >
          <option value=''>Select...</option>
          {Array.from(this.props.list.channels).map((objValue, index) => (
            <option value={objValue.name} key={index}>
              {objValue.name.concat(" | " + objValue.description)}
            </option>
          ))}
        </AvField>
      );
    }
  }
}

const mapStateToProps = state => {
  return {
    list: state.payouts
  };
};

const mapDispatchToProps = dispatch => {
  return {
    fetchPayouts: () => {
      dispatch(fetchAllPayout());
    }
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(PayoutSelector);
