import Jsona from "jsona";
import http_remit from "../../api/http_remit";
import { showNotification } from "../notification";
import { setReduxDropdownActionLength } from "../redux_actions";

import { reactTableFilters, reactTableSort } from "../utils";
import Config from "../../../config";
const { pageSize: defaultPageSize, pageIndex: defaultPageIndex } =
  Config.defaultMeta;

const strGeneralError =
  "There was a problem in connecting to the server. Please check your internet and try again.";

export function dismissPayoutError() {
  return (dispatch) => {
    dispatch({ type: "DISMISS_PAYOUT_ERROR" });
  };
}

export function UpdatePayoutUserDetails(userDetails) {
  return (dispatch) => {
    dispatch({
      type: "UPDATE_PAYOUT_REDUX_DETAILS",
      userDetails: userDetails,
    });
  };
}

export function ClearSelectedPayout() {
  return (dispatch) => {
    dispatch({ type: "CLEAR_SELECTED_PAYOUTS" });
  };
}

export function SetPayoutLoading(loadingMsg) {
  return (dispatch) => {
    dispatch({ type: "PAYOUT_LOADING", loadingMsg: loadingMsg });
  };
}

export function fetchAllPayout() {
  let apiBaseUrl = `${
    process.env.REACT_APP_REMITTANCE_BASE_URL
  }${"api/v1/remittance/channels/"}`;

  var params = {};
  params.useSwitchAuthentication = true;

  const request = http_remit.get(apiBaseUrl, { params });

  const dataFormatter = new Jsona();

  return (dispatch) => {
    request.then(
      ({ data }) => {
        let res = dataFormatter.deserialize(data);

        dispatch({
          type: "FETCH_CHANNELS",
          data: res,
          meta: data.meta,
        });
      },
      (error) => {
        console.log("Error Request", error);
      }
    );
  };
}

export function GetPayout(payoutId) {
  let apiBaseUrl = `${
    process.env.REACT_APP_REMITTANCE_BASE_URL
  }${"api/v1/id/payouts/"}`.concat(payoutId);

  const request = http_remit.get(apiBaseUrl);
  const dataFormatter = new Jsona();

  return (dispatch) => {
    request.then(
      ({ data }) => {
        let res = dataFormatter.deserialize(data);

        dispatch({
          type: "FETCH_A_PAYOUT",
          payload: res,
          payoutId: payoutId,
        });
      },
      (error) => {
        dispatch({ type: "ERROR_PAYOUTS", errorMsg: error.messages });
      }
    );
  };
}

export function GetPayoutList(state, instance) {
  let apiBaseUrl = `${
    process.env.REACT_APP_REMITTANCE_BASE_URL
  }${"api/v1/id/payouts/"}`;

  let params = {
    ...Config.defaultMeta,
  };

  //override defaults if there is a state
  if (state) {
    let { pageIndex, pageSize, sortBy, filters } = state;

    params = {
      page: {
        size: pageSize,
        number: pageIndex,
      },
    };

    if (sortBy)
      if (sortBy.length > 0) {
        params = {
          ...params,
          sort: reactTableSort(sortBy),
        };
      }

    if (filters)
      if (filters.length > 0) {
        params = {
          ...params,
          filter: reactTableFilters(filters),
        };
      }
  } else {
    params = {
      page: {
        number: defaultPageIndex + 1,
        size: defaultPageSize,
      },
    };
  }

  params.module = "payout";

  const request = http_remit.get(apiBaseUrl, { params });
  const dataFormatter = new Jsona();

  return (dispatch) => {
    request.then(
      ({ data }) => {
        let res = dataFormatter.deserialize(data);
        dispatch(setReduxDropdownActionLength(data.meta.itemTotal));

        dispatch({
          type: "FETCH_PAYOUTS",
          meta: data.meta,
          payload: res,
        });
      },
      (error) => {
        if (
          String(error) ===
          "TypeError: Cannot read property 'parseError' of undefined"
        ) {
          dispatch({ type: "ACCOUNT_LOADING_STOP" });
        } else {
          dispatch(
            showNotification({ type: "error", message: error.messages })
          );
          dispatch({
            type: "ERROR_PAYOUTS",
            errorMsg: strGeneralError,
          });
        }
      }
    );
  };
}

export function CreatePayout(objParams) {
  let apiBaseUrl = `${
    process.env.REACT_APP_REMITTANCE_BASE_URL
  }${"api/v1/id/payouts/"}`;

  let objRequest = {
    data: {
      type: "payout",
      attributes: {
        ...objParams,
      },
    },
  };

  console.log(objRequest);

  const request = http_remit.post(apiBaseUrl, objRequest);
  const dataFormatter = new Jsona();

  return (dispatch) => {
    dispatch(SetPayoutLoading("Creating payout information..."));

    request.then(
      ({ data }) => {
        let res = dataFormatter.deserialize(data);
        dispatch({ type: "PAYOUT_CREATED", payload: res });
        dispatch(
          showNotification({
            type: "success",
            message: "Payout has successfully been created!",
          })
        );
        dispatch({ type: "PAYOUT_LOADING_STOP" });
      },
      (error) => {
        dispatch(showNotification({ type: "error", message: error.messages }));
        dispatch({
          type: "ERROR_PAYOUTS",
          errorMsg: error.messages,
          payload: objParams,
        });
      }
    );
  };
}

export function UpdatePayout(objParams, payoutId) {
  let apiBaseUrl = `${
    process.env.REACT_APP_REMITTANCE_BASE_URL
  }${"api/v1/id/payouts/"}`.concat(payoutId);

  let objRequest = {
    data: {
      type: "payout",
      attributes: {
        ...objParams,
      },
    },
  };

  const request = http_remit.patch(apiBaseUrl, objRequest);
  const dataFormatter = new Jsona();

  return (dispatch) => {
    dispatch(SetPayoutLoading("Updating payout information..."));

    request.then(
      ({ data }) => {
        let res = dataFormatter.deserialize(data);
        dispatch({ type: "PAYOUT_UPDATED", payload: res });
        dispatch(
          showNotification({
            type: "success",
            message: "Payout has successfully been updated!",
          })
        );
      },
      (error) => {
        dispatch(showNotification({ type: "error", message: error.messages }));
        dispatch({
          type: "ERROR_PAYOUTS",
          errorMsg: error.messages,
          payload: objParams,
        });
      }
    );
  };
}
