import React from 'react';
import $ from 'jquery';

window.jQuery = $;
window.$ = $;
global.jQuery = $;

// admin access
const AdminDashboard = React.lazy(() => import('./Containers/Dashboard/AdminDashboard'));
const Accounts = React.lazy(() => import('./Containers/Accounts/Index'));
const AccountDetails = React.lazy(() => import('./Containers/Accounts/AccountDetails'));
const Users = React.lazy(() => import('./Containers/Users/Index'));
const Loyalty = React.lazy(() => import('./Containers/Loyalty/Index'));
const LoyaltyDetails = React.lazy(() => import('./Containers/Loyalty/LoyaltyDetails'));
const LoyaltyPointsTable = React.lazy(() => import('./Containers/Loyalty/LoyaltyPointsTable'));

// agent access
const Topups = React.lazy(() => import('./Containers/Topups/Index'));


// common access
const Transactions = React.lazy(() => import('./Containers/Transactions/Index'));
const UserDetails = React.lazy(() => import('./Containers/Users/userDetails'));

// users access
const Dashboard = React.lazy(() => import('./Containers/Dashboard/Index'));
const Payments = React.lazy(() => import('./Containers/Payments/Index'));
const Transfer = React.lazy(() => import('./Containers/Transfer/Index'));
const RequestMoney = React.lazy(() => import('./Containers/RequestMoney/MyRequest'));
const IncomingRequest = React.lazy(() => import('./Containers/RequestMoney/IncomingRequest'));

const Settings = React.lazy(() => import('./Containers/Settings/Index'));
const Kyc = React.lazy(() => import('./Containers/Kyc/Index'));

const Cards = React.lazy(() => import('./Containers/Cards/Index'));
const CardDetails = React.lazy(() => import('./Containers/Cards/CardDetails'));

const Rewards = React.lazy(() => import('./Containers/Rewards/Index'));
const RewardsDetails = React.lazy(() => import('./Containers/Rewards/RewardsDetails'));
const VouchersTable = React.lazy(() => import('./Containers/Rewards/VouchersTable'));

const Microfinance = React.lazy(() => import('./Containers/Microfinance/Index'));

// start remittance

const SendMoney = React.lazy(() =>
  import("./Containers/Remittance/Transactions/SendMoney")
);

const routes = [

    { path: '/admin/dashboard', exact: true, name: 'Admin Dashboard', component: AdminDashboard },

    { path: '/dashboard', exact: true, name: 'Dashboard', component: Dashboard },
    { path: '/payments', exact: true, name: 'Payments', component: Payments },
    { path: '/transfer', exact: true, name: 'Transfer', component: Transfer },
    { path: '/topup', exact: true, name: 'Top up', component: Topups },
    { path: '/request-money', exact: true, name: 'Request Money', component: RequestMoney },
    { path: '/incoming-request', exact: true, name: 'Incoming Request Money', component: IncomingRequest },
    { path: '/transactions', exact: true, name: 'Transactions', component: Transactions },

    { path: '/accounts', exact: true, name: 'Accounts', component: Accounts },
    { path: '/accounts/:id', exact: true, name: 'View Fund', component: AccountDetails },
    
    { path: '/customers', exact: true, name: 'Customers', component: Users },
    { path: '/customers/:id', exact: true, name: 'View Customer', component: UserDetails },
    { path: '/customers/kyc/:id', exact: true, name: 'Kyc / Aml', component: Kyc },

    { path: '/settings', exact: true, name: 'Settings', component: Settings },
    { path: '/settings/profile', exact: true, name: 'Profile', component: UserDetails },

    { path: '/settings/cards', exact: true, name: 'Payment Cards', component: Cards },
    { path: '/settings/cards/:id', exact: true, name: 'View Cards', component: CardDetails },

    { path: '/loyalty', exact: true, name: 'Loyalty Program', component: Loyalty },
    { path: '/loyalty/:id', exact: true, name: 'View Loyalty', component: LoyaltyDetails },
    { path: '/settings/rewards', exact: true, name: 'Loyalty Rewards', component: LoyaltyPointsTable },

    
    { path: '/rewards', exact: true, name: 'Loyalty Program', component: Rewards },
    { path: '/rewards/:id', exact: true, name: 'View Loyalty', component: RewardsDetails },
    { path: '/vouchers', exact: true, name: 'Vouchers', component: VouchersTable },
    
    { path: '/microfinance', exact: true, name: 'Banking Integration', component: Microfinance },

    {
        path: "/remittance/sendmoney/:customerId",
        permissionId: "new-transactions-page",
        exact: true,
        name: "Send Money",
        component: SendMoney
    },
];

export default routes;