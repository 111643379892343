import Config from "../../../config";

const initState = {
    open              : false,
    error             : false,
    errorMsg          : "",
    loading           : false,
    loadingText       : "Processing Request...",
    meta              : Config.defaultMeta,
    data              : [],
    selectedLoyalty   : {},
    selectedReward    : {},
    loyaltyInformation: {},
    loyaltyCreated    : false,
    loyaltyUpdated    : false,
    loyaltyId         : null,
    rewardCreated     : false,
    rewardUpdated     : false,
    rewardId          : null,
    redeemed          : false,
    rewardsCollected  : false,
    redeemReward      : [],
    defaultSorted     : [
        {
            id  : "createdAt",
            desc: true
        }
    ]
};

export default function loyalty(state = initState, action) {
    switch (action.type) {

        case "CLEAR_SELECTED_LOYALTY": 
        case "CLEAR_SELECTED_REWARD" : 
            return {
                ...state,
                error          : false,
                loading        : false,
                loading_image  : false,
                loyaltyId      : null,
                selectedLoyalty: {},
                selectedReward : {},
                redeemReward   : [],
                redeemed       : false
            };

                
        case "FETCH_LOYALTIES": 
        case "FETCH_REWARDS"  : 
            return {
                ...state,
                error  : false,
                loading: false,
                meta   : action.meta,
                data   : action.payload,
            };

        case "FETCH_A_LOYALTY": 
            return {
                ...state,
                error          : false,
                loading        : false,
                loading_image  : false,
                loyaltyId      : action.loyaltyId,
                selectedLoyalty: { ...action.payload }
            };

        case "FETCH_A_REWARD": 
            return {
                ...state,
                error         : false,
                loading       : false,
                loading_image : false,
                rewardId      : action.rewardId,
                selectedReward: { ...action.payload }
            };

        case "REDEEMED_REWARDS": 
            return {
                ...state,
                error        : false,
                loading      : false,
                loading_image: false,
                redeemed     : true,
                redeemReward : { ...action.payload }
            };

        case "COLLECT_REWARDS": 
            return {
                ...state,
                error           : false,
                loading         : false,
                loading_image   : false,
                rewardsCollected: true,
            };
            
        case "LOYALTY_CREATED": 
            return {
                ...state,
                error          : false,
                loading        : false,
                loading_image  : false,
                loyaltyCreated : true,
                selectedLoyalty: { ...action.payload }
            };

        case "LOYALTY_UPDATED": 
            return {
                ...state,
                error          : false,
                loading        : false,
                loading_image  : false,
                loyaltyUpdated : true,
                selectedLoyalty: { ...action.payload }
            };

        case "REWARD_CREATED": 
            return {
                ...state,
                error         : false,
                loading       : false,
                loading_image : false,
                rewardCreated : true,
                selectedReward: { ...action.payload }
            };

        case "REWARD_UPDATED": 
            return {
                ...state,
                error         : false,
                loading       : false,
                loading_image : false,
                rewardUpdated : true,
                selectedReward: { ...action.payload }
            };

        case "ERROR_LOYALTY": 
        case "ERROR_REWARDS": 
            return {
                ...state,
                error          : true,
                loading        : false,
                errorMsg       : action.errorMsg,
                selectedLoyalty: {  ...action.payload }
        };

        case "LOYALTY_LOADING": 
            return {
                ...state,
                loading: true,
            };

        case "LOYALTY_LOADING_STOP": 
        case "REWARDS_LOADING_STOP": 
            return {
                ...state,
                error  : false,
                loading: false
            };
            
        default: 
            return state;
                
        }
}