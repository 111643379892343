import Jsona from "jsona";
import http_remit from "../../api/http_remit";
import Config from "../../../config";
import { reactTableFilters, reactTableSort } from "../utils";
import { showNotification } from "../notification";

import { setReduxDropdownActionLength } from "../redux_actions";

const strGeneralError =
  "There was a problem in connecting to the server. Please check your internet and try again.";

const {
  pageSize: defaultPageSize,
  pageIndex: defaultPageIndex
} = Config.defaultMeta;

export function fetchCurrency(state) {
  let apiBaseUrl = `${
    process.env.REACT_APP_REMITTANCE_BASE_URL
  }${"api/v1/currencies/"}`;

  let params = {
    ...Config.defaultMeta
  };

  //override defaults if there is a state
  if (state) {
    let { pageIndex, pageSize, sortBy, filters } = state;

    params = {
      page: {
        size: pageSize,
        number: pageIndex
      }
    };

    if (sortBy)
      if (sortBy.length > 0) {
        params = {
          ...params,
          sort: reactTableSort(sortBy)
        };
      }

    if (filters)
      if (filters.length > 0) {
        params = {
          ...params,
          filter: reactTableFilters(filters)
        };
      }
  } else {
    params = {
      page: {
        number: defaultPageIndex + 1,
        size: defaultPageSize
      }
    };
  }

  params.module = "currency";
  params.useSwitchAuthentication = true;

  const request = http_remit.get(apiBaseUrl, { params });

  const dataFormatter = new Jsona();

  return dispatch => {
    request.then(
      ({ data }) => {
        let res = dataFormatter.deserialize(data);

        dispatch(setReduxDropdownActionLength(data.meta.itemTotal));
        dispatch({
          type: "FETCH_CURRENCY",
          data: res,
          meta: data.meta
        });
      },
      error => {
        console.log("Error Request", error);
      }
    );
  };
}

export function fetchAllCurrencies() {
  let apiBaseUrl = `${
    process.env.REACT_APP_REMITTANCE_BASE_URL
  }${"api/v1/currencies/"}`;

  var params = {};

  params.useSwitchAuthentication = true;

  const request = http_remit.get(apiBaseUrl, { params });

  const dataFormatter = new Jsona();

  return dispatch => {
    request.then(
      ({ data }) => {
        let res = dataFormatter.deserialize(data);

        dispatch({
          type: "FETCH_CURRENCY",
          data: res,
          meta: data.meta
        });
      },
      error => {
        console.log("Error Request", error);
      }
    );
  };
}

export function fetchRemittanceCurrencies() {
  let apiBaseUrl = `${
    process.env.REACT_APP_REMITTANCE_BASE_URL
  }${"api/v1/currencies/"}`;

  let params = {
    filter: { canRemit: 1 },
    useSwitchAuthentication: true
  };

  const request = http_remit.get(apiBaseUrl, { params });

  const dataFormatter = new Jsona();

  return dispatch => {
    request.then(
      ({ data }) => {
        let res = dataFormatter.deserialize(data);

        dispatch({
          type: "FETCH_CURRENCY",
          data: res,
          meta: data.meta
        });
      },
      error => {
        console.log("Error Request", error);
      }
    );
  };
}

export function fetchACurrency(currencyId) {
  let apiBaseUrl = `${
    process.env.REACT_APP_REMITTANCE_BASE_URL
  }${"api/v1/currencies/"}`.concat(currencyId);

  let params = {
    params: {
      include: Config.REACT_APP_CLIENTS_INCLUDE
    },
    useSwitchAuthentication: true
  };

  const request = http_remit.get(apiBaseUrl, { params });

  const dataFormatter = new Jsona();

  return dispatch => {
    request.then(
      ({ data }) => {
        let res = dataFormatter.deserialize(data);

        dispatch({
          type: "FETCH_A_CURRENCY",
          payload: res,
          currencyId: currencyId
        });
      },
      error => {
        dispatch(showNotification({ type: "error", message: error }));
        dispatch({ type: "ERROR_CURRENCY", errorMsg: strGeneralError });
      }
    );
  };
}

export function createCurrency(objFormValues) {
  let apiBaseUrl = `${
    process.env.REACT_APP_REMITTANCE_BASE_URL
  }${"api/v1/currencies/"}`;

  let datas = {
    data: {
      type: "Currency",
      attributes: {
        ...objFormValues
      }
    }
  };

  var params = {
    useSwitchAuthentication: true
  };

  const request = http_remit.post(apiBaseUrl, datas, { params });

  const dataFormatter = new Jsona();

  return dispatch => {
    dispatch(setCurrencyLoading("Creating currency..."));
    request.then(
      ({ data }) => {
        let res = dataFormatter.deserialize(data);

        dispatch({ type: "CURRENCY_CREATED", payload: res });
        dispatch(
          showNotification({
            type: "success",
            message: "Currency has successfully been created!"
          })
        );
      },
      error => {
        dispatch({
          type: "ERROR_CURRENCY",
          errorMsg: error
        });
      }
    );
  };
}

export function updateCurrency(objFormValues, currencyId) {
  let apiBaseUrl = `${
    process.env.REACT_APP_REMITTANCE_BASE_URL
  }${"api/v1/currencies/"}`.concat(currencyId);

  var params = {
    useSwitchAuthentication: true
  };

  const request = http_remit.put(
    apiBaseUrl,
    {
      data: {
        type: "Client",
        attributes: {
          ...objFormValues
        }
      }
    },
    { params }
  );

  return dispatch => {
    request.then(
      ({ data }) => {
        dispatch(setCurrencyLoading("Creating client address..."));
        dispatch({ type: "CURRENCY_UPDATED" });
        dispatch(
          showNotification({
            type: "success",
            message: "Currency has successfully been updated!"
          })
        );
      },
      error => {
        dispatch({
          type: "ERROR_CURRENCY",
          errorMsg: error
        });
      }
    );
  };
}

export function setCurrencyLoading(loadingMsg) {
  return dispatch => {
    dispatch({
      type: "CURRENCY_LOADING",
      loadingMsg: loadingMsg
    });
  };
}

export function defaultCurrency() {
  return dispatch => {
    dispatch({
      type: "CURRENCY_LIST_DEFAULT"
    });
  };
}

export function dismissError() {
  return dispatch => {
    dispatch({ type: "DISMISS_ERROR" });
  };
}
