import Config from "../../../config";

const initState = {
  open: false,
  error: false,
  errorMsg: "",
  loading: false,
  loaded: false,
  isLoading: false,
  loading_image: false,
  firstLoad: true,
  loading_image: false,
  loadingText: "Processing Request...",
  meta: Config.defaultMeta,
  data: [],
  channels: [],
  selectedPayout: {},
  payoutInformation: {},
  payoutCreated: false,
  payoutUpdated: false,
  payoutId: null,
  defaultSorted: [
    {
      id: "createdAt",
      desc: false
    }
  ]
};

export default function payouts(state = initState, action) {
  switch (action.type) {
    case "FETCH_CHANNELS":
      return {
        ...state,
        error: false,
        channels: action.data,
        meta: action.meta,
        loading: false,
        loading_image: false,
        loaded: true,
        firstLoad: false
      };

    case "UPDATE_PAYOUT_REDUX_DETAILS":
      return {
        ...state,
        error: false,
        loading: false,
        loading_image: false,
        selectedPayout: {
          ...state.selectedPayout,
          ...action.userDetails
        }
      };

    case "CLEAR_SELECTED_PAYOUTS":
      return {
        ...state,
        error: false,
        loading: false,
        loading_image: false,
        payoutId: null,
        selectedPayout: {}
      };

    case "FETCH_PAYOUTS":
      return {
        ...state,
        error: false,
        loading: false,
        loading_image: false,
        loaded: true,
        firstLoad: false,
        meta: action.meta,
        data: action.payload
      };

    case "FETCH_A_PAYOUT":
      return {
        ...state,
        error: false,
        loading: false,
        loading_image: false,
        payoutId: action.payoutId,
        selectedPayout: { ...action.payload }
      };

    case "PAYOUT_CREATED":
      return {
        ...state,
        error: false,
        loading: false,
        payoutCreated: true,
        loading_image: false,
        selectedPayout: { ...action.payload }
      };

    case "PAYOUT_UPDATED":
      return {
        ...state,
        error: false,
        loading: false,
        payoutUpdated: true,
        loading_image: false,
        selectedPayout: { ...action.payload }
      };

    case "PAYOUT_LOADING":
      return {
        ...state,
        loading: true,
        isLoading: true
      };

    case "PAYOUT_LOADING_STOP":
      return {
        ...state,
        error: false,
        loading: false,
        loading_image: false,
        isLoading: false
      };

    case "ERROR_PAYOUTS":
      return {
        ...state,
        error: true,
        loading: false,
        loading_image: false,
        errorMsg: action.errorMsg,
        selectedPayout: {
          ...action.payload
        }
      };

    case "DISMISS_PAYOUT_ERROR":
      return {
        ...state,
        error: false,
        loading: false,
        loading_image: false,
        errorMsg: action.errorMsg
      };

    case "TENANT_PAYOUT_LIST_DEFAULT":
    case "DISMISS_ERROR":
    case "CLEAR_TENANT_PAYOUT_DATA":
      return initState;

    default:
      return state;
  }
}
