import React from "react";
import { connect } from "react-redux";

import { AvField } from "availity-reactstrap-validation";

import formHelper from "../../../store/actions/helpers/formHelper";

import { fetchPurpose } from "../../../store/actions/settings/purposes";

class PurposeSelection extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {
    this.props.fetchPurpose();
    if (this.props.onMount !== undefined) {
      this.props.onMount();
    }
  }

  handleChange = (event) => {
    if (this.props.onChange !== undefined) {
      this.props.onChange(event);
    }
  };

  render() {
    let { formatLabel } = formHelper;

    return (
      <AvField
        type="select"
        name={this.props.name}
        id={this.props.id}
        label={
          this.props.label &&
          formatLabel({ name: this.props.label, required: this.props.required })
        }
        required={this.props.required}
        onChange={this.handleChange}
      >
        <option key={-1} value="">
          Select {formatLabel({ name: this.props.name }) || "Purpose"}...
        </option>
        {this.props.purposeList.map((purpose, index) => {
          return (
            <option value={purpose.name} key={index}>
              {purpose.name}
            </option>
          );
        })}
      </AvField>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    purposeList: state.purposes.data,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    fetchPurpose: (state) => {
      dispatch(fetchPurpose(state));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(PurposeSelection);
