
import Config from "../../../config";

const initState = {
    open              : false,
    error             : false,
    errorMsg          : "",
    loading           : false,
    loading_image     : false,
    loadingText       : "Processing Request...",
    meta              : Config.defaultMeta,
    data              : [],
    selectedAccount   : {},
    accountInformation: {},
    accountCreated    : false,
    accountUpdated    : false,
    accountId         : null,
    defaultSorted     : [
        {
            id  : "createdAt",
            desc: true
        }
    ],
    kycStarted: false,
    kycUpdated: false,
    rewards   : [],
    vouchers  : [],
    bankData  : []
};

export default function accounts(state = initState, action) {
    switch (action.type) {

        case "CLEAR_SELECTED_ACCOUNTS": 
            return {
                ...state,
                error          : false,
                loading        : false,
                loading_image  : false,
                accountId      : null,
                selectedAccount: {},
            };
            
        case "FETCH_ACCOUNTS": 
        case "FETCH_CARDS"   : 
            return {
                ...state,
                error        : false,
                loading      : false,
                loading_image: false,
                meta         : action.meta,
                data         : action.payload,
            };

        case "FETCH_BANK_ACCOUNT"   : 
            return {
                ...state,
                error        : false,
                loading      : false,
                loading_image: false,
                meta         : action.meta,
                bankData     : action.payload,
            };

        case "FETCH_A_ACCOUNT": 
            return {
                ...state,
                error          : false,
                loading        : false,
                loading_image  : false,
                accountId      : action.accountId,
                selectedAccount: { ...action.payload },
            };

        case "FETCH_ACCOUNT_REWARDS": 

            let rewardList = [];
            if(action.payload.customer){
                rewardList = action.payload.customer.loyaltyPoints;
            }

            return {
                ...state,
                error        : false,
                loading      : false,
                loading_image: false,
                accountId    : action.accountId,
                rewards      : rewardList,
            };

        case "FETCH_ACCOUNT_VOUCHERS": 

            let vouchersList = [];
            if(action.payload.customer){
                vouchersList = action.payload.customer.loyaltyRewardRedemptions;
            }

            return {
                ...state,
                error        : false,
                loading      : false,
                loading_image: false,
                accountId    : action.accountId,
                vouchers     : vouchersList,
            };

        case "ACCOUNT_CREATED": 
            return {
                ...state,
                error          : false,
                loading        : false,
                accountCreated : true,
                loading_image  : false,
                selectedAccount: { ...action.payload },
            };

        case "ACCOUNT_CARD_CREATED": 
            return {
                ...state,
                error         : false,
                loading       : false,
                accountUpdated: true,
                loading_image : false
            };

        case "ACCOUNT_UPDATED" : 
            return {
                ...state,
                error          : false,
                loading        : false,
                accountUpdated : true,
                loading_image  : false,
                selectedAccount: { ...action.payload },
            };

        case "ACCOUNT_LOADING": 
            return {
                ...state,
                loading: true,
            };

        case "ACCOUNT_LOADING_STOP": 
            return {
                ...state,
                error        : false,
                loading      : false,
                loading_image: false,
            };

        case "KCY_CREATED": 
            return {
                ...state,
                error          : false,
                loading        : false,
                loading_image  : false,
                kycStarted     : true,
                kycUpdated     : true,
                selectedAccount: { 
                    customer: {
                        kyc: { ...action.payload }
                    }
                }
            };

        case "KCY_UPDATED": 
            return {
                ...state,
                error          : false,
                loading        : false,
                loading_image  : false,
                kycUpdated     : true,
                selectedAccount: { 
                    customer: {
                        kyc: { ...action.payload }
                    }
                }
            };

        case "ERROR_ACCOUNTS": 
            return {
                ...state,
                error          : true,
                loading        : false,
                loading_image  : false,
                errorMsg       : action.errorMsg,
                selectedAccount: { 
                    customer: {
                        kyc: { ...action.payload }
                    }
                }
            };

        case "ERROR_LINKING": 
            return {
                ...state,
                error          : true,
                loading        : false,
                loading_image  : false,
                errorMsg       : action.errorMsg
            };

        default: 
            return state;
    }
}