import React, { Component } from "react";

import { connect } from "react-redux";
import { toggleReduxActions } from "../../../store/actions/redux_actions";

import { OverlayTrigger, Tooltip } from 'react-bootstrap';

import {
  ButtonDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  UncontrolledTooltip
} from "reactstrap";

class TableActions extends Component {

  toggle = i => {
    const { dropdownOpen } = this.props.redux_actions;

    const newArray = dropdownOpen.map((element, index) => {
      return index === i ? !element : false;
    });

    this.props.toggleDropdownMenus(newArray);
  };

  handleViewDetails = moduleId => {
    this.props.handleViewDetails(this.props.moduleId)
  };

  handleEditDetails = moduleId => {
    this.props.handleEditDetails(this.props.moduleId)
  };

  handleDeleteDetails = moduleId => {
    this.props.handleDeleteDetails(this.props.moduleId)
  }

  handleSuspendDetails = moduleId => {
    this.props.handleSuspendDetails(this.props.moduleId)
  }

  handleTopUpDetails = (moduleId) => {
    this.props.handleTopUpDetails(this.props.moduleId, this.props.accoundNumber)
  }

  handleWithdrawDetails = (moduleId) => {
    this.props.handleWithdrawDetails(this.props.moduleId, this.props.accoundNumber)
  }

  handleDepositDetails = (moduleId) => {
    this.props.handleDepositDetails(this.props.moduleId, this.props.accoundNumber)
  }

  handleRequestMoneyApproval = (moduleId) => {
    this.props.handleRequestMoneyApproval(this.props.moduleId, this.props.accoundNumber)
  }

  handlRejectRequestMoney = (moduleId) => {
    this.props.handlRejectRequestMoney(this.props.moduleId, this.props.accoundNumber)
  }

  handleRedeem = (moduleId) => {
    this.props.handleRedeem(this.props.moduleId, this.props.redeemable)
  }

  render() {

    const { user } = this.props;
    let isOpen = this.props.redux_actions.dropdownOpen[this.props.selectedTableId];

    return (
      <React.Fragment>
        <ButtonDropdown
          isOpen={isOpen}
          className={`text-decoration-none`}
          toggle={() => {
            this.toggle(this.props.selectedTableId);
          }}
        >
          <DropdownToggle
            color=""
            className={`drp_actions text-decoration-none`}
            id={"actions-" + this.props.selectedTableId}
          >
            <i className="fa fa-ellipsis-v"></i>
          </DropdownToggle>
          {isOpen && (
            <DropdownMenu right>

              {this.props.allowViewFirst && <OverlayTrigger
                placement='left'
                overlay={<Tooltip id={`view-tooltip-top`}>View Detail Information</Tooltip>}
              >
                <DropdownItem key={0} className="btn_actions" onClick={this.handleViewDetails.bind(this)} >
                  <i className={"icon feather icon-eye"}></i> View
                        </DropdownItem>
              </OverlayTrigger>}

              {this.props.allowEdit &&
                <OverlayTrigger
                  placement='left'
                  overlay={<Tooltip id={`edit-tooltip-top`}>Edit Details</Tooltip>}
                >
                  <DropdownItem key={1} className="btn_actions" onClick={this.handleEditDetails.bind(this)} >
                    <i className={"icon feather icon-edit-2"}></i> Edit
                        </DropdownItem>
                </OverlayTrigger>
              }

              {this.props.allowSuspend && <OverlayTrigger
                placement='left'
                overlay={<Tooltip id={`edit-tooltip-top`}>Suspend Account</Tooltip>}
              >
                <DropdownItem key={1} className="btn_actions" onClick={this.handleSuspendDetails.bind(this)} >
                  <i className={"icon feather icon-alert-circle"}></i> Suspend
                        </DropdownItem>
              </OverlayTrigger>}

              {this.props.allowDeletion && <OverlayTrigger
                placement='left'
                overlay={<Tooltip id={`edit-tooltip-top`}>Delete Account Details</Tooltip>}
              >
                <DropdownItem key={1} className="btn_actions" onClick={this.handleDeleteDetails.bind(this)} >
                  <i className={"icon feather icon-trash"}></i> Delete
                        </DropdownItem>
              </OverlayTrigger>}

              {this.props.allowTopup &&
              ["agent"].includes(user.role) && 
              <OverlayTrigger
                placement='left'
                overlay={<Tooltip id={`edit-topup`}>Topup Account</Tooltip>}
              >
                <DropdownItem key={1} className="btn_actions" onClick={this.handleTopUpDetails.bind(this)} >
                  <i className={"icon feather icon-arrow-up"}></i> Topup
                </DropdownItem>
              </OverlayTrigger>}

              {this.props.allowDeposit && 
              ["agent"].includes(user.role) && 
              <OverlayTrigger
                placement='left'
                overlay={<Tooltip id={`edit-topup`}>Make deposit to the Account</Tooltip>}
              >
                <DropdownItem key={1} className="btn_actions" onClick={this.handleDepositDetails.bind(this)} >
                  <i className={"icon feather icon-briefcase"}></i> Deposit
                </DropdownItem>
              </OverlayTrigger>}

              {this.props.allowWithdraw && 
              ["agent"].includes(user.role) && 
              <OverlayTrigger
                placement='left'
                overlay={<Tooltip id={`edit-topup`}>Make withdrawal from account</Tooltip>}
              >
                <DropdownItem key={1} className="btn_actions" onClick={this.handleWithdrawDetails.bind(this)} >
                  <i className={"icon feather icon-arrow-down"}></i> Withdraw
                </DropdownItem>
              </OverlayTrigger>}

              {this.props.allowAcceptRequestMoney && 
              ["customer"].includes(user.role) && <OverlayTrigger
                placement='left'
                overlay={<Tooltip id={`edit-topup`}>Accept Request Money Request</Tooltip>}
              >
                <DropdownItem key={1} className="btn_actions" onClick={this.handleRequestMoneyApproval.bind(this)} >
                  <i className={"icon feather icon-check text-success"}></i> Accept
                </DropdownItem>
              </OverlayTrigger>}

              {this.props.rejectAcceptRequestMoney && 
              ["customer"].includes(user.role) && <OverlayTrigger
                placement='left'
                overlay={<Tooltip id={`edit-topup`}>Reject Request Money Request</Tooltip>}
              >
                <DropdownItem key={1} className="btn_actions" onClick={this.handlRejectRequestMoney.bind(this)} >
                  <i className={"icon feather icon-x text-danger"}></i> Reject
                </DropdownItem>
              </OverlayTrigger>}

              {this.props.handleRedeem && 
              ["customer"].includes(user.role) && <OverlayTrigger
                placement='left'
                overlay={<Tooltip id={`edit-topup`}>Redeem Selected Reward</Tooltip>}
              >
                <DropdownItem key={1} className="btn_actions" onClick={this.handleRedeem.bind(this)} >
                  <i className={"fa fa-trophy text-warning"}></i> Redeem
                </DropdownItem>
              </OverlayTrigger>}

            </DropdownMenu>
          )}
        </ButtonDropdown>
        <UncontrolledTooltip placement="top" target={"actions-" + this.props.selectedTableId}>
          Select an action
              </UncontrolledTooltip>
      </React.Fragment>
    );
  }

}


const mapStateToProps = state => {
  return {
    ...state.auth,
    redux_actions: state.redux_actions
  };
};

const mapDispatchToProps = dispatch => {
  return {
    toggleDropdownMenus: (toggle, actionParams) => {
      dispatch(toggleReduxActions(toggle, actionParams));
    }
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(TableActions);