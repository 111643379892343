import React from "react";
import { connect } from "react-redux";
import { fetchAllCurrencies } from "../../../store/actions/remittance/currencies";

import { AvField } from "availity-reactstrap-validation";
import _ from "lodash";

class CurrencySelector extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};

    this.handleChange = this.handleChange.bind(this);
  }

  componentDidMount() {
    this.props.fetchAllCurrencies();
  }

  handleChange(event) {
    if (this.props.onChange !== undefined) {
      this.props.onChange(event);
    }
  }

  render() {
    if (this.props.returnCode) {
      return _.map(this.props.list.data, (objValue, index) => {
        if (parseInt(objValue.id) === this.props.value) {
          return (
            <span key={index} className="text-bold">
              {objValue.code}
            </span>
          );
        }
      });
    } else {
      return (
        <AvField
          type="select"
          name={this.props.name}
          id={this.props.id}
          label={this.props.label}
          required={this.props.required}
          placeholder={this.props.placeholder}
          style={{ width: "100%" }}
          value={this.props.value}
          disabled={this.props.disabled}
          usecodes={this.props.usecodes}
        >
          <option value="">Select...</option>
          {Array.from(this.props.list.data).map((objValue, index) => (
            <option
              value={this.props.usecodes ? objValue.code : objValue.id}
              key={index}
            >
              {objValue.code.concat(" - " + objValue.name)}
            </option>
          ))}
        </AvField>
      );
    }
  }
}

const mapStateToProps = (state) => {
  return {
    list: state.currency,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    fetchAllCurrencies: () => {
      dispatch(fetchAllCurrencies());
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(CurrencySelector);
