export function reactTableFilters(state) {

    let objFilter = null;

    if (state) {

        let tempFilterObj = {};

        state.filtered.forEach(filter => {
            tempFilterObj[filter.id] = filter.value;
        });

        objFilter = tempFilterObj;

    }

    return objFilter;

}

export function reactTableSort(state){

    let objSort = null;

    if (state) {

        objSort = state.sorted.map((item, index) => {
            let direction = item.desc ? "-" : "";
            return direction + item.id;
        });

    }

    return objSort;

}