import Config from "../../../config";

const initState = {
  open: false,
  error: false,
  errorMsg: "",
  firstLoad: true,
  loaded: false,
  loading: false,
  loading_image: false,
  loadingText: "Processing Request...",
  meta: Config.defaultMeta,
  data: [],
  selectedPurpose: {
    name: "",
    createdAt: "",
    updatedAt: "",
  },
  purposeCreated: false,
  purposeUpdated: false,
  purposeAction: null,
  purposeId: null,
  defaultSorted: [
    {
      id: "createdAt",
      desc: true,
    },
  ],
  dropdownOpen: new Array(10).fill(false),
};

export default function purpose(state = initState, action) {
  switch (action.type) {
    case "FETCH_PURPOSE":
      return {
        ...state,
        error: false,
        data: action.data,
        meta: action.meta,
        loading: false,
        loading_image: false,
        loaded: true,
        firstLoad: false,
      };

    case "FETCH_A_PURPOSE":
      return {
        ...state,
        error: false,
        selectedPurpose: {
          ...action.payload,
        },
        purposeId: action.purposeId,
        loading: false,
        loading_image: false,
      };

    case "PURPOSE_CREATED":
      return {
        ...state,
        error: false,
        selectedPurpose: {
          ...action.payload,
        },
        purposeCreated: true,
        loading: false,
        loading_image: false,
      };

    case "PURPOSE_UPDATED":
      return {
        ...state,
        error: false,
        purposeUpdated: true,
        loading: false,
        loading_image: false,
      };

    case "ERROR_PURPOSE":
      return {
        ...state,
        error: true,
        errorMsg: action.errorMsg.messages,
        loading: false,
        loading_image: false,
      };

    case "PURPOSE_LOADING":
      return {
        ...state,
        error: false,
        loading: true,
        loadingText: action.loadingMsg,
        loading_image: false,
      };

    case "PURPOSE_LIST_DEFAULT":
    case "DISMISS_ERROR":
    case "CLEAR_PURPOSE_DATA":
      return initState;

    //for general notification
    case "NOTIFICATION_DISSMISS":
      return {
        ...state,
        loading: false,
        loading_image: false,
      };

    default:
      return state;
  }
}
