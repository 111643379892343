import React from "react";

import { Badge } from 'reactstrap'
import _ from 'lodash'

class TransactionStatusBadge extends React.Component {

    constructor(props) {
        super(props);
        this.state = {};

        this.handleChange = this.handleChange.bind(this)
    }


    handleChange(event) {
        if (this.props.onChange !== undefined) {
            this.props.onChange(event)
        }
    }


    render() {

        let {
            value
        } = this.props

        let color = null

        switch (value) {
            case "approved":
            case "collected":
            case "refunded":
                color = "success"
                break;

            case "draft":
            case "for-approval":
                color = "secondary"
                break;
            
            case "for-collection":
                color = "info"
                break;

            case "pending":
            case "on-hold":
            case "processing":
                color = "warning"
                break;

            default:
                color = "danger"
                break;
        }

        return <Badge color={color}>
            {_.startCase(value || 'pending')}
        </Badge>

    }

}

export default TransactionStatusBadge